import React, {useEffect, useState} from 'react';
import {useSite} from "../../siteContext";
import {collection, doc, getDoc, getDocs, updateDoc} from "firebase/firestore";
import {db} from "../../firebase/firebase";
import RefGetterBasedOnSite from "../../utils/RefGetterBasedOnSite";


const VisibleDraws = () => {
    const { site } = useSite();
    const [drawsToShow, setDrawsToShow] = useState([]);
    const [topDrawsToShow, setTopDrawsToShow] = useState([]);
    const [mumbaiDraws, setMumbaiDraws] = useState([]);
    const [selectedMumbaiDraw, setSelectedMumbaiDraw] = useState('');
    const [selectedMumbaiDrawId, setSelectedMumbaiDrawId] = useState(null);

    const dbRef = RefGetterBasedOnSite(site).drawsToShowRef;

    const fetchConfigData = async () => {
        try {
            const configDocRef = doc(db, 'config', dbRef);
            const configDocSnapshot = await getDoc(configDocRef);
            console.log("fetching config data:", configDocSnapshot)
            if (configDocSnapshot.exists()) {
                const configData = configDocSnapshot.data();
                setDrawsToShow(configData['draws-to-show'] || []);
                setTopDrawsToShow(configData['top-draws-to-show'] || []);
            } else {
                console.log('Document does not exist');
            }
        } catch (error) {
            console.error('Error fetching config data: ', error);
        }
    };

    const fetchMumbaiDraws = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'mumbai-pattern-draws'));
            const drawsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setMumbaiDraws(drawsData);
        } catch (error) {
            console.error('Error fetching Mumbai draws:', error);
        }
    };

    const addToConfig = async (key) => {
        try {
            const configDocRef = doc(db, 'config', dbRef);
            const drawsToAdd = selectedMumbaiDrawId ? [selectedMumbaiDrawId] : [];

            // Retrieve the current draws for each key
            let currentDrawsToShow = drawsToShow;
            let currentTopDrawsToShow = topDrawsToShow;

            if (key === 'top-draws-to-show') {
                // Add to both drawsToShow and topDrawsToShow
                const updatedDrawsToShowSet = new Set([...currentDrawsToShow, ...drawsToAdd]);
                const updatedTopDrawsToShowSet = new Set([...currentTopDrawsToShow, ...drawsToAdd]);

                const updatedData = {
                    'draws-to-show': Array.from(updatedDrawsToShowSet),
                    'top-draws-to-show': Array.from(updatedTopDrawsToShowSet),
                };

                await updateDoc(configDocRef, updatedData);
            } else if (key === 'draws-to-show') {
                // Add to drawsToShow only
                const updatedDrawsToShowSet = new Set([...currentDrawsToShow, ...drawsToAdd]);

                const updatedData = {
                    'draws-to-show': Array.from(updatedDrawsToShowSet),
                };

                await updateDoc(configDocRef, updatedData);
            }

            console.log('Draw IDs added to configuration successfully');
            fetchConfigData();
        } catch (error) {
            console.error(`Error adding draw IDs to ${key}:`, error);
        }

        resetSelections();
    };

    const removeFromConfig = async (key, drawId) => {
        try {
            const configDocRef = doc(db, 'config', dbRef);
            const updatedList = (key === 'draws-to-show' ? drawsToShow : topDrawsToShow).filter(id => id !== drawId);
            await updateDoc(configDocRef, { [key]: updatedList });
            console.log(`Draw ID removed from ${key} successfully`);
            fetchConfigData();
        } catch (error) {
            console.error(`Error removing draw ID from ${key}:`, error);
        }
    };

    const resetSelections = () => {
        setDrawsToShow([]);
        setTopDrawsToShow([]);
        setSelectedMumbaiDrawId(null);
        setSelectedMumbaiDraw('');
    };

    useEffect(() => {
        fetchMumbaiDraws();
        fetchConfigData();
    }, [site]);

    return (
        <div className="flex flex-col items-center justify-center mx-auto mt-16 p-8 max-w-4xl bg-white shadow-lg rounded-2xl">
            <div className="flex flex-col  w-full mb-8">
                <h2 className="text-3xl font-bold mb-4 text-gray-800">Add Draws to Show on <span class={"capitalize"}>{site}</span></h2>
                <div className="w-full space-y-4 mb-8">
                    <h3 className="text-xl font-bold mb-2 text-gray-700">Select Mumbai Draw</h3>
                    <div className="mb-4">
                        <select
                            id="mumbaiDraw"
                            className="border p-3 rounded-md w-full text-gray-700 bg-gray-100 focus:outline-none focus:border-blue-500"
                            value={selectedMumbaiDraw}
                            onChange={(e) => {
                                const selectedOption = e.target.options[e.target.selectedIndex];
                                const selectedId = selectedOption.getAttribute('data-id');
                                setSelectedMumbaiDrawId(selectedId);
                                setSelectedMumbaiDraw(e.target.value);
                            }}
                        >
                            <option value="" className="text-gray-500">Select Draw</option>
                            {mumbaiDraws.map((draw) => (
                                <option key={draw.id} value={draw.id} data-id={draw.id}>
                                    {draw.drawName} ({draw.startTime} - {draw.closingTime})
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex space-x-4">
                        <button
                            onClick={() => addToConfig('draws-to-show')}
                            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-200"
                        >
                            Add to Draw Normal
                        </button>
                        { !(site === "android") &&
                            <button
                                onClick={() => addToConfig('top-draws-to-show')}
                                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition-colors duration-200"
                            >
                                Add to Top Draws
                            </button>
                        }
                    </div>
                </div>
                <div className="mb-8">
                    <h3 className="text-2xl font-bold mb-4 text-gray-800">Draws to Show</h3>
                    <table className="min-w-full bg-white shadow-md rounded-lg">
                        <thead>
                        <tr className="bg-gray-100 text-left">
                            <th className="py-2 px-4 text-gray-700">Draw Name</th>
                            <th className="py-2 px-4 text-gray-700"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {drawsToShow.map((drawId, index) => (
                            <tr key={index} className="border-t">
                                <td className="py-2 px-4 text-gray-700">{drawId}</td>
                                <td className="py-2 px-4 text-right">
                                    <button
                                        onClick={() => removeFromConfig('draws-to-show', drawId)}
                                        className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600 transition-colors duration-200"
                                    >
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                { !(site === "android") &&

                    <div>
                        <h3 className="text-2xl font-bold mb-4 text-gray-800">Top Draws to Show</h3>
                        <table className="min-w-full bg-white shadow-md rounded-lg">
                            <thead>
                            <tr className="bg-gray-100 text-left">
                                <th className="py-2 px-4 text-gray-700">Top Draw Name</th>
                                <th className="py-2 px-4 text-gray-700"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {topDrawsToShow.map((drawId, index) => (
                                <tr key={index} className="border-t">
                                    <td className="py-2 px-4 text-gray-700">{drawId}</td>
                                    <td className="py-2 px-4 text-right">
                                        <button
                                            onClick={() => removeFromConfig('top-draws-to-show', drawId)}
                                            className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600 transition-colors duration-200"
                                        >
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
    );
};

export default VisibleDraws;
