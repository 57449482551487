import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase';

const MumbaiPatternSpecialDetails = () => {
    const [draws, setDraws] = useState([]);
    const [selectedDraw, setSelectedDraw] = useState('');
    const [formData, setFormData] = useState({
        single: '',
        panel: '',
        jodi: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({ type: '', content: '' });
    const [drawsWithSpecialInfo, setDrawsWithSpecialInfo] = useState([]);

    const fetchDraws = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'mumbai-pattern-draws'));
            const drawsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setDraws(drawsData);
            
            // Filter draws with special information
            const drawsWithInfo = drawsData.filter(draw => 
                draw.single || draw.panel || draw.jodi
            );
            setDrawsWithSpecialInfo(drawsWithInfo);
        } catch (error) {
            console.error('Error fetching draws:', error);
            setMessage({ type: 'error', content: 'Failed to fetch draws' });
        }
    };

    useEffect(() => {
        fetchDraws();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedDraw) {
            setMessage({ type: 'error', content: 'Please select a draw' });
            return;
        }

        setIsLoading(true);
        try {
            const drawRef = doc(db, 'mumbai-pattern-draws', selectedDraw);
            await updateDoc(drawRef, {
                single: formData.single,
                panel: formData.panel,
                jodi: formData.jodi
            });

            setMessage({ type: 'success', content: 'Special information updated successfully!' });
            setFormData({ single: '', panel: '', jodi: '' });
            setSelectedDraw('');
            fetchDraws(); // Refresh the list
        } catch (error) {
            setMessage({ type: 'error', content: 'Error updating special information' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteSpecialInfo = async (drawId) => {
        if (!window.confirm('Are you sure you want to delete this special information?')) {
            return;
        }

        setIsLoading(true);
        try {
            const drawRef = doc(db, 'mumbai-pattern-draws', drawId);
            await updateDoc(drawRef, {
                single: '',
                panel: '',
                jodi: ''
            });

            setMessage({ type: 'success', content: 'Special information deleted successfully!' });
            fetchDraws(); // Refresh the list
        } catch (error) {
            setMessage({ type: 'error', content: 'Error deleting special information' });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center mx-auto mt-16 p-8">
            <h1 className="text-4xl font-bold mb-8 text-white">Special Draw Information</h1>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 w-full max-w-7xl">
                {/* Existing form */}
                <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6">
                    <div className="space-y-6">
                        <div>
                            <label className="text-xl font-semibold mb-2 block text-black">
                                Select Draw
                            </label>
                            <select
                                className="text-xl p-3 border border-gray-800 rounded-md w-full text-black"
                                value={selectedDraw}
                                onChange={(e) => setSelectedDraw(e.target.value)}
                                required
                            >
                                <option value="">Select a draw</option>
                                {draws.map((draw) => (
                                    <option key={draw.id} value={draw.id}>
                                        {draw.drawName} ({draw.startTime} - {draw.closingTime})
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="text-xl font-semibold mb-2 block text-black">
                                Aaj Ka Single
                            </label>
                            <input
                                type="text"
                                name="single"
                                className="text-xl p-3 border border-gray-800 rounded-md w-full text-black"
                                value={formData.single}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div>
                            <label className="text-xl font-semibold mb-2 block text-black">
                                Draw Name Panel
                            </label>
                            <input
                                type="text"
                                name="panel"
                                className="text-xl p-3 border border-gray-800 rounded-md w-full text-black"
                                value={formData.panel}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div>
                            <label className="text-xl font-semibold mb-2 block text-black">
                                Jodi Dhamaka
                            </label>
                            <input
                                type="text"
                                name="jodi"
                                className="text-xl p-3 border border-gray-800 rounded-md w-full text-black"
                                value={formData.jodi}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <button
                            type="submit"
                            disabled={isLoading}
                            className={`w-full mt-4 px-6 py-3 text-lg font-semibold text-white rounded-md transition duration-300 ease-in-out
                                ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
                        >
                            {isLoading ? 'Updating...' : 'Update Special Information'}
                        </button>

                        {message.content && (
                            <div className={`mt-4 p-3 rounded-md ${
                                message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
                            }`}>
                                {message.content}
                            </div>
                        )}
                    </div>
                </form>

                {/* New section: List of draws with special information */}
                <div className="bg-white shadow-md rounded-lg p-6">
                    <h2 className="text-2xl font-semibold mb-6 text-black">Current Special Information</h2>
                    <div className="space-y-4">
                        {drawsWithSpecialInfo.length === 0 ? (
                            <p className="text-gray-500 text-center">No draws have special information</p>
                        ) : (
                            drawsWithSpecialInfo.map((draw) => (
                                <div key={draw.id} className="border border-gray-200 rounded-lg p-4 bg-gray-50 text-black">
                                    <div className="flex justify-between items-start mb-2">
                                        <h3 className="text-lg font-semibold text-black">{draw.drawName}</h3>
                                        <button
                                            onClick={() => handleDeleteSpecialInfo(draw.id)}
                                            className="px-3 py-1 text-sm text-red-600 hover:text-red-800 transition-colors"
                                            disabled={isLoading}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2 text-sm">
                                        {draw.single && (
                                            <div>
                                                <span className="font-medium">Aaj Ka Single:</span> {draw.single}
                                            </div>
                                        )}
                                        {draw.panel && (
                                            <div>
                                                <span className="font-medium">Draw Name Panel:</span> {draw.panel}
                                            </div>
                                        )}
                                        {draw.jodi && (
                                            <div>
                                                <span className="font-medium">Jodi Dhamaka:</span> {draw.jodi}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MumbaiPatternSpecialDetails;
