import React, { useState, useEffect } from 'react';
import {useSite} from "../../siteContext";
import { db } from '../../firebase/firebase';
import { collection, getDocs, doc, setDoc, deleteDoc, getDoc } from 'firebase/firestore';
import RefGetterBasedOnSite from "../../utils/RefGetterBasedOnSite";

function NoticeBoardPage() {
    const { site } = useSite();
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [notices, setNotices] = useState([]);

    useEffect(() => {
        fetchNotices();
    }, []);

    const [configData, setConfigData] = useState(null);

    const dbRef = RefGetterBasedOnSite(site).drawsToShowRef;

    const fetchNotices = async () => {
        try {
            const configDocRef = doc(db, 'config', dbRef);
            const configSnapshot = await getDoc(configDocRef);
            const data = configSnapshot.data();

            setConfigData(data);
            setNotices(data.notices || []);
            console.log("fetching notices:", JSON.stringify(data));
        } catch (error) {
            console.error('Error fetching notices:', error);
        }
    };

    const handleAddNotice = async () => {
        if (!title.trim() || !subtitle.trim()) {
            alert('Please fill in both title and subtitle');
            return;
        }

        try {
            const configDocRef = doc(db, 'config', dbRef);
            const configSnapshot = await getDoc(configDocRef);
            const data = configSnapshot.data();
            const currentNotices = data.notices || [];

            const newNotice = {
                id: Date.now().toString(),
                title: title.trim(),
                subtitle: subtitle.trim(),
                timestamp: new Date().toISOString()
            };

            await setDoc(configDocRef, {
                ...configData,
                notices: [newNotice, ...currentNotices]
            });

            setTitle('');
            setSubtitle('');
            fetchNotices();
        } catch (error) {
            console.error('Error adding notice:', error);
            alert('Failed to add notice');
        }
    };

    const handleDeleteNotice = async (noticeId) => {
        try {
            const configDocRef = doc(db, 'config', dbRef);
            const configSnapshot = await getDoc(configDocRef);
            const data = configSnapshot.data();
            const updatedNotices = data.notices.filter(notice => notice.id !== noticeId);

            await setDoc(configDocRef, {
                ...configData,
                notices: updatedNotices
            });

            fetchNotices();
        } catch (error) {
            console.error('Error deleting notice:', error);
            alert('Failed to delete notice');
        }
    };

    return (
        <div className="flex flex-col items-center justify-center mx-auto mt-16 p-8 max-w-4xl bg-white shadow-lg rounded-2xl">
            <h1 className="text-5xl mb-8 font-extrabold text-gray-800">Notice Board</h1>

            <div className="flex flex-col space-y-6 w-full">
                <div className="flex flex-col">
                    <label className="text-lg font-semibold text-gray-600 mb-2">Notice Title</label>
                    <input
                        type="text"
                        placeholder="Enter Notice Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="border border-gray-300 p-3 w-full text-gray-800 rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 outline-none"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-lg font-semibold text-gray-600 mb-2">Notice Subtitle</label>
                    <textarea
                        placeholder="Enter Notice Subtitle"
                        value={subtitle}
                        onChange={(e) => setSubtitle(e.target.value)}
                        className="border border-gray-300 p-3 w-full h-32 text-gray-800 rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 outline-none resize-none"
                    />
                </div>

                <button
                    onClick={handleAddNotice}
                    className="w-full py-3 bg-blue-500 text-white font-semibold rounded-lg shadow hover:bg-blue-600 transition duration-300"
                >
                    Add Notice
                </button>
            </div>

            {notices.length > 0 && (
                <div className="mt-12 w-full">
                    <h2 className="text-4xl mb-6 font-bold text-gray-800">All Notices</h2>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border border-gray-200">
                            <thead>
                                <tr className="bg-gray-50">
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subtitle</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Timestamp</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {notices.map((notice) => (
                                    <tr key={notice.id}>
                                        <td className="px-6 py-4 whitespace-nowrap text-gray-800">{notice.title}</td>
                                        <td className="px-6 py-4 text-gray-800">{notice.subtitle}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-gray-500">
                                            {new Date(notice.timestamp).toLocaleString()}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <button
                                                onClick={() => handleDeleteNotice(notice.id)}
                                                className="px-4 py-2 bg-red-500 text-white font-semibold rounded-lg shadow hover:bg-red-600 transition duration-300"
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
}

export default NoticeBoardPage;