import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import { useSite } from "../siteContext";

const Sidebar = ({ onLogout, isSuperAdmin, userData }) => {
    const { site, setSite } = useSite();
    const location = useLocation();

    const handleChange = (event) => {
        setSite(event.target.value);
    };

    const isActive = (path) => location.pathname === path;

    return (
        <div className="bg-gray-900 text-white w-64 min-h-screen flex flex-col justify-between">
            <div>
                <div className="p-6">
                    <h1 className="text-2xl font-bold">DP ANNA ADMIN</h1>
                </div>
                {isSuperAdmin && (
                    <div className="px-4">
                        <div className="text-gray-400 text-xs uppercase font-bold mb-2">Admin</div>
                        <div className="mb-2">
                            <Link to="/super-admin" className={`block px-4 py-2 rounded-md ${isActive('/super-admin') ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}>
                                Super Admin
                            </Link>
                        </div>
                        <div className="mb-2">
                            <Link to="/create-draws" className={`block px-4 py-2 rounded-md ${isActive('/create-draws') ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}>
                                Create Draws
                            </Link>
                        </div>
                        
                        <div className="mt-4 border-t border-gray-700 pt-4">
                            <Link to="/form-data"
                                  className={`block px-4 py-2 rounded-md ${isActive('/form-data') ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}>
                                Form Data
                            </Link>
                        </div>
                        
                        <div className="mb-4">
                            <label htmlFor="dpanna-selector" className="block text-sm font-medium text-gray-300 mb-1">
                                Selected Site
                            </label>
                            <select
                                id="dpanna-selector"
                                value={site}
                                onChange={handleChange}
                                className="w-full px-3 py-2 text-base bg-gray-800 border border-gray-700 text-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500"
                            >
                                <option value="dpanna-main">DP Anna Main</option>
                                <option value="dpanna-golden-news">Golden News</option>
                                <option value="android">Android</option>
                            </select>
                        </div>
                    </div>
                )}
                <nav className="px-4">
                    <ul>
                        {(isSuperAdmin || (userData && userData.hasPattiRowAccess)) && (
                            <li className="mb-2">
                                <Link to="/multipleNumbersPatti"
                                              className={`block px-4 py-2 rounded-md ${isActive('/drawsOnMobile') ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}>
                                    3x3 Patti
                                </Link>
                            </li>
                        )}

                        {(isSuperAdmin &&(
                            <li className="mb-2">
                                <Link to="/notice-board"
                                            className={`block px-4 py-2 rounded-md ${isActive('/notice-board') ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}>
                                    Notice Board
                                </Link>
                            </li>
                        ))}
                        {isSuperAdmin && (
                            <>
                                {site === "android" && (
                                    <li className="mb-2">
                                        <Link to="/drawsOnMobile"
                                              className={`block px-4 py-2 rounded-md ${isActive('/drawsOnMobile') ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}>
                                            Draws on Android App
                                        </Link>
                                    </li>
                                )}
                                {!(site === "android") &&
                                    <li className="mb-2">
                                        <Link to="/visibleDraws"
                                              className={`block px-4 py-2 rounded-md ${isActive('/visibleDraws') ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}>
                                            Draws To Show
                                        </Link>
                                    </li>
                                }
                                <li className="mb-2">
                                    <Link to="/addNews"
                                          className={`block px-4 py-2 rounded-md ${isActive('/addNews') ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}>
                                        News
                                    </Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/addLuckyDraw"
                                          className={`block px-4 py-2 rounded-md ${isActive('/addLuckyDraw') ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}>
                                        Add Lucky Draw
                                    </Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/whatsAppNumbers"
                                          className={`block px-4 py-2 rounded-md ${isActive('/whatsAppNumbers') ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}>
                                        WhatsApp Numbers
                                    </Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/highlightPatti"
                                          className={`block px-4 py-2 rounded-md ${isActive('/highlightPatti') ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}>
                                        Highlight Patti
                                    </Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/specialMumbai"
                                          className={`block px-4 py-2 rounded-md ${isActive('/specialMumbai') ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}>
                                        Special Mumbai Details
                                    </Link>
                                </li>
                            </>
                        )}
                        <li className="mt-4 border-t border-gray-700 pt-4">
                            <Link to="/delhi-pattern"
                                  className={`block px-4 py-2 rounded-md ${isActive('/delhi-pattern') ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}>
                                Delhi Pattern
                            </Link>
                        </li>
                        <li className="mt-2">
                            <Link to="/mumbai-pattern" className={`block px-4 py-2 rounded-md ${isActive('/mumbai-pattern') ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}>
                                Mumbai Pattern
                            </Link>
                        </li>
                        <li className="mt-4 border-t border-gray-700 pt-4">
                            <Link to="/change-password" className={`block px-4 py-2 rounded-md ${isActive('/change-password') ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}>
                                Change Password
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="p-4 border-t border-gray-700">
                <button
                    className="block w-full text-left text-white bg-gray-800 hover:bg-gray-700 py-2 px-4 rounded-md"
                    onClick={onLogout}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                    Logout
                </button>
            </div>
        </div>
    );
};

export default Sidebar;