import React, {useEffect, useState} from 'react';
import {auth, db} from './firebase/firebase'; // Import the auth service
import Sidebar from './common/Sidebar'; // Import the Sidebar component
import LoginPage from "./pages/login/Login";
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import DelhiPatternPage from "./pages/DelhiPatternPage";
import MumbaiPatternPage from "./pages/MumbaiPatternPage";
import CreateDrawsPage from "./pages/superAdmin/CreateDrawsPage";
import SuperAdminPage from "./pages/superAdmin/SuperAdmin";
import { css } from '@emotion/react';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import {doc, getDoc} from "firebase/firestore";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import AddNewsPage from "./pages/common/addNewsPage";
import DrawsOnAndroid from "./pages/android/drawsOnAndroid";
import WhatsAppNumbersAddOnWeb from "./pages/common/whatsAppNumbersAddOnWeb";
import LuckyDrawPage from "./pages/common/luckyDrawPage";
import {SiteProvider} from "./siteContext";
import VisibleDraws from "./pages/common/visibleDraws";
import MultipleNumbersPatti from "./pages/common/multipleNumbersPatti";
import FormDataPage from './pages/FormData';
import NoticeBoardPage from './pages/admin/NoticeBoardPage';
import HighlightPatti from './pages/common/highlightPatti';
import MumbaiPatternSpecialDetails from './pages/common/mumbaiPatternSpecialDetails';

const styles = {
    spinner: {
        border: '4px solid rgba(0, 0, 0, 0.1)',
        borderTop: '4px solid #ffffff',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        animation: 'spin 1s linear infinite',
        margin: 'auto',
        marginTop: '50vh',
    },
    '@keyframes spin': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
    },
};

const spinnerStyle = css(styles.spinner);

const Spinner = () => (
    <div css={spinnerStyle}></div>
);


function App() {
    const [user, setUser] = useState(auth.currentUser);
    const [userData, setUserData] = useState(null);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true); // Add loading state
    const [site, setSite] = useState("dpanna-main");


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async currentUser => {
            setUser(currentUser);
            if (currentUser) {
                try {
                    const userDocRef = doc(db, 'users', currentUser.uid);
                    const userDoc = await getDoc(userDocRef);
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        setUserData(userData);
                        setIsSuperAdmin(userData.superAdmin);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            } else {
                setIsSuperAdmin(false);
            }
            setIsLoading(false); // Set loading to false after authentication check
        });
        return unsubscribe;
    }, []);

    const handleLogin = (email, password) => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                setUser(user);
            })
            .catch((error) => {
                const errorMessage = error.message;
                setErrorMessage(errorMessage);
            });
    };

    const handleLogout = () => {
        auth.signOut()
            .then(() => {
                setUser(null);
                setIsSuperAdmin(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    if (isLoading) {
        return <Spinner />;
    }


    return (
        <SiteProvider>
            <div className="flex min-h-screen bg-gray-700">
            <Router>
                {user ? (
                    <>
                        <Sidebar onLogout={handleLogout} isSuperAdmin={isSuperAdmin} userData={userData}/>
                        <div className="flex-1 text-white transition duration-500 ease-in-out">
                            <Switch>
                                {isSuperAdmin && (
                                    <Route path="/super-admin" component={SuperAdminPage} />
                                )}
                                <Route path="/create-draws" component={CreateDrawsPage} />
                                <Route path="/delhi-pattern">
                                    <DelhiPatternPage userData={userData} />
                                </Route>
                                <Route path="/mumbai-pattern">
                                    <MumbaiPatternPage userData={userData} />
                                </Route>
                                <Route path="/change-password" component={ChangePasswordPage}/>
                                {isSuperAdmin && (
                                    <Route path="/addNews" component={AddNewsPage}/>
                                )}
                                {isSuperAdmin && (
                                    <Route path="/drawsOnMobile" component={DrawsOnAndroid}/>
                                )}
                                {isSuperAdmin && (
                                    <Route path="/whatsAppNumbers" component={WhatsAppNumbersAddOnWeb}/>
                                )}
                                {isSuperAdmin && (
                                    <Route path="/addLuckyDraw" component={LuckyDrawPage}/>
                                )}
                                {isSuperAdmin && (
                                    <Route path="/visibleDraws" component={VisibleDraws}/>
                                )}
                                {isSuperAdmin && (
                                    <Route path="/multipleNumbersPatti" component={MultipleNumbersPatti}/>
                                )}
                                 {isSuperAdmin && (
                                    <Route path="/highlightPatti" component={HighlightPatti}/>
                                )}
                                {isSuperAdmin && (
                                    <Route path="/specialMumbai" component={MumbaiPatternSpecialDetails}/>
                                )}
                                {isSuperAdmin && (
                                    <Route path="/notice-board" component={NoticeBoardPage} />
                                )}
                                 {/*Form Data Route  */}
                                 <Route path="/form-data" component={FormDataPage} />
                                 {/*Notice Board Route  */}
                                 
                            </Switch>
                        </div>
                    </>
                ) : (
                    <>
                        <Route path="/login">
                            <LoginPage onLogin={handleLogin} errorMessage={errorMessage} />
                        </Route>
                        <Redirect exact from="/" to={"/login"} />
                        <Redirect exact from="" to={"/login"} />
                    </>
                )}
            </Router>
        </div>
        </SiteProvider>
    );
}





export default App;
