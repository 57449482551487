import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import {collection, getDocs, updateDoc, doc, setDoc, getDoc, query, addDoc, where, deleteDoc} from 'firebase/firestore';
import 'react-datepicker/dist/react-datepicker.css';
import { db } from '../firebase/firebase';

function MumbaiPatternPage({ userData }) {
    const [draws, setDraws] = useState([]);
    const [selectedDraw, setSelectedDraw] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedStatus, setSelectedStatus] = useState('open');
    const [selectedPatti, setSelectedPatti] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [pattiError, setPattiError] = useState('');

    const [successMessage, setSuccessMessage] = useState('');

    const fetchDraws = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'mumbai-pattern-draws'));

            let drawsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            if (!userData.superAdmin) {
                const userDraws = userData['mumbai-pattern'] || [];
                drawsData = drawsData.filter(draw => userDraws.includes(draw.id));
            }

            setDraws(drawsData);
        } catch (error) {
            console.error('Error fetching draws:', error);
        }
    };

    useEffect(() => {
        fetchDraws();
    }, [userData]); // Refetch when user changes

    const resetInputs = () => {
        setSelectedDraw('');
        setSelectedStatus('open');
        setSelectedPatti('');
        setPattiError('');
    };

    const handleDelete = async (docId) => {
        if (!window.confirm('Are you sure you want to delete this record?')) {
            return;
        }

        try {
            setIsLoading(true);
            const docRef = doc(db, 'all-draws-beta', docId);
            await deleteDoc(docRef);
            setSuccessMessage('Record successfully deleted');
            resetInputs();
        } catch (error) {
            setPattiError(`Error deleting record: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleModifyPatti = async () => {
        if (!selectedDraw || !selectedPatti || !selectedStatus) {
            console.error('Invalid input data');
            return;
        }

        setIsLoading(true);

        // Check for deletion command
        if (selectedPatti === 'xxx') {
            const dateConverted = selectedDate.toLocaleDateString('en-GB');
            const allDrawsRef = collection(db, 'all-draws-beta');
            const queryRef = query(allDrawsRef, where('date', '==', dateConverted), where('idDrawName', '==', selectedDraw));
            const querySnapshot = await getDocs(queryRef);
            const existingDraw = querySnapshot.docs[0];
            
            if (existingDraw) {
                await handleDelete(existingDraw.id);
                return;
            } else {
                setPattiError('No record found to delete');
                setIsLoading(false);
                return;
            }
        }

        const pattiDigits = selectedPatti.split('').map(Number);

        if (selectedPatti.length !== 3 || !selectedPatti.match(/^\d+$/)) {
            setPattiError('Patti must be a 3-digit number');
            return;
        }

        if (pattiDigits[1] === 0 && pattiDigits[2] === 0) {
            modifyPattiAsync()
            return;
        }

        if (pattiDigits[0] === 0 && pattiDigits[1] === 0) {
            modifyPattiAsync()
            return;
        }

        // 1 2 3
        if (pattiDigits[0] <= pattiDigits[1] && pattiDigits[2] === 0) {
            modifyPattiAsync()
            return;
        }

        if (pattiDigits[0] === pattiDigits[1] && pattiDigits[1] === pattiDigits[2] && pattiDigits[0] === pattiDigits[2]) {
            modifyPattiAsync()
            return;
        }

        if ((pattiDigits[0] === pattiDigits[1] || pattiDigits[1] === pattiDigits[2] ) && pattiDigits[0] <= pattiDigits[1] && pattiDigits[1] === pattiDigits[2]) {
            modifyPattiAsync()
            return;
        }

        if ((pattiDigits[0] <= pattiDigits[1] && pattiDigits[1] === pattiDigits[2]) || (pattiDigits[0] === pattiDigits[1] && pattiDigits[1] <= pattiDigits[2])) {
            modifyPattiAsync()
            return;
        }

        if (pattiDigits[0] > pattiDigits[1] || pattiDigits[1] > pattiDigits[2]) {
            setPattiError('Patti digits must be in non-decreasing order');
            return;
        }

        modifyPattiAsync()
    };

    const modifyPattiAsync = async () => {
        try {
            setIsLoading(true);
            setPattiError('');

            const dateConverted = selectedDate.toLocaleDateString('en-GB');
            const allDrawsRef = collection(db, 'all-draws-beta');
            const queryRef = query(allDrawsRef, where('date', '==', dateConverted), where('idDrawName', '==', selectedDraw));
            const querySnapshot = await getDocs(queryRef);
            const existingDraw = querySnapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));

            if (existingDraw.length > 0) {
                const docRef = doc(db, 'all-draws-beta', existingDraw[0].id);
                const updateData = {
                    [`${selectedStatus}RoundPatti`]: selectedPatti,
                    fullPatti: '',
                };
                
                // Add timestamp update when setting close patti
                if (selectedStatus === 'close') {
                    updateData.timestamp = new Date();
                }
                
                await updateDoc(docRef, updateData);

                setSuccessMessage(`Patti successfully updated for ${selectedStatus === 'open' ? 'Open' : 'Close'} Round of ${existingDraw[0].data.drawName}: ${selectedPatti}`);
            } else {
                const currentDraw = draws.find(draw => draw.id === selectedDraw);

                if (currentDraw) {
                    const dataToCreate = {
                        idDrawName: selectedDraw,
                        openRoundPatti: selectedPatti,
                        closeRoundPatti: '',
                        fullPatti: '',
                        startTime: currentDraw.startTime,
                        closingTime: currentDraw.closingTime,
                        date: dateConverted,
                        drawName: currentDraw.drawName,
                        timestamp: new Date(),
                        pattern: "mumbaiPattern"
                    };
                    await addDoc(allDrawsRef, dataToCreate);

                    setSuccessMessage(`Patti successfully created for Open Round of ${currentDraw.drawName}: ${selectedPatti}`);
                }
            }

            resetInputs(); // Reset input fields and errors after successful modification
            setSelectedPatti('');

            fetchDraws(); // Assuming fetchDraws is correctly implemented

        } catch (error) {
            setPattiError(`Error modifying patti: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };



    return (
        <div className="flex flex-col items-center justify-center mx-auto mt-16 p-8 text-black">
            <h1 className="text-4xl font-bold mb-8 text-white">Delhi Pattern</h1>

            <div className="flex flex-col w-full max-w-md bg-white shadow-md rounded-lg p-6">
                <div className="mb-6">
                    <label className="text-xl font-semibold mb-2 block">Date</label>
                    <DatePicker
                        className="text-xl p-3 border border-gray-800 rounded-md w-full"
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        dateFormat="dd/MM/yyyy"
                    />
                </div>

                <div className="mb-6">
                    <label className="text-xl font-semibold mb-2 block">Draw</label>
                    <select
                        className="text-xl p-3 border border-gray-800 rounded-md w-full"
                        value={selectedDraw}
                        onChange={(e) => setSelectedDraw(e.target.value)}
                    >
                        <option value="">Select Draw</option>
                        {draws.map((draw) => (
                            <option key={draw.id} value={draw.id}>
                                {draw.drawName} ({draw.startTime} - {draw.closingTime})
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-6">
                    <label className="text-xl font-semibold mb-2 block">Status</label>
                    <select
                        className="text-xl p-3 border border-gray-800 rounded-md w-full"
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                        <option value="open">Open Round</option>
                        <option value="close">Close Round</option>
                    </select>
                </div>

                <div className="mb-6">
                    <label className="text-xl font-semibold mb-2 block">Patti</label>
                    <input
                        type="text"
                        className="text-xl p-3 border border-gray-800 rounded-md w-full focus:outline-none focus:border-blue-500"
                        value={selectedPatti}
                        onChange={(e) => setSelectedPatti(e.target.value)}
                        maxLength={3}
                    />
                    {pattiError && <p className="text-red-500 text-sm mt-1">{pattiError}</p>}
                </div>

                <button
                    onClick={handleModifyPatti}
                    className="mt-4 px-6 py-3 text-lg font-semibold bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ease-in-out"
                >
                    Confirm Patti
                </button>

                {successMessage && (
                    <p className="text-green-500 text-sm mt-4">{successMessage}</p>
                )}
            </div>
        </div>
    );
}

export default MumbaiPatternPage;
