import React, { useState, useEffect } from 'react';
import {
    collection,
    getDocs,
    updateDoc,
    doc,
    arrayUnion,
    arrayRemove,
    writeBatch,
    setDoc
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db, functions } from '../../firebase/firebase';
import {httpsCallable} from 'firebase/functions';



function SuperAdminPage() {
    const [users, setUsers] = useState([]);
    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserPassword, setNewUserPassword] = useState('');
    const [mumbaiDraws, setMumbaiDraws] = useState([]);
    const [selectedMumbaiDraw, setSelectedMumbaiDraw] = useState('');
    const [selectedDelhiDraw, setSelectedDelhiDraw] = useState('');
    const [delhiDraws, setDelhiDraws] = useState([]);
    const [hasPattiRowAccess, setHasPattiRowAccess] = useState(false);

    const fetchUsers = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'users'));
            const usersData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setUsers(usersData);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchDraws = async pattern => {
        try {
            const querySnapshot = await getDocs(collection(db, pattern));
            const drawsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            return drawsData;
        } catch (error) {
            console.error(`Error fetching ${pattern} draws:`, error);
        }
    };

    const fetchDrawNames = async pattern => {
        try {
            const querySnapshot = await getDocs(collection(db, pattern));
            const drawNames = querySnapshot.docs.map(doc => ({
                id: doc.id,
                name: doc.data().drawName, // Assuming the name is stored as drawName
            }));
            return drawNames;
        } catch (error) {
            console.error(`Error fetching ${pattern} draw names:`, error);
        }
    };

    useEffect(() => {
        fetchUsers();
        fetchDraws('mumbai-pattern-draws').then(data => setMumbaiDraws(data));
        fetchDraws('delhi-pattern-draws').then(data => setDelhiDraws(data));
        fetchDrawNames('mumbai-pattern-draws').then(data => {
            setMumbaiDraws(prevDraws => prevDraws.map(draw => ({ ...draw, name: data.find(d => d.id === draw.id)?.name })));
        });
        fetchDrawNames('delhi-pattern-draws').then(data => {
            setDelhiDraws(prevDraws => prevDraws.map(draw => ({ ...draw, name: data.find(d => d.id === draw.id)?.name })));
        });
    }, []);


    const assignDraws = async (userId, selectedDraw, pattern) => {
        try {
            if (!selectedDraw) {
                throw new Error('No draws selected to assign.');
            }

            const userRef = doc(db, 'users', userId);
            await updateDoc(userRef, {
                [pattern]: arrayUnion(selectedDraw),
            });

            // Fetch the updated user list
            await fetchUsers();
        } catch (error) {
            console.error('Error assigning draws:', error);
            // Display an error message or perform any desired UI action
        }
    };

    const removeDraw = async (userId, drawId, pattern) => {
        try {
            const userRef = doc(db, 'users', userId);
            await updateDoc(userRef, {
                [pattern]: arrayRemove(drawId),
            });

            // Fetch the updated user list
            await fetchUsers();
        } catch (error) {
            console.error('Error removing draw:', error);
            // Display an error message or perform any desired UI action
        }
    };

    const togglePattiRowAccess = async (userId, currentAccess) => {
        try {
            const userRef = doc(db, 'users', userId);
            const newAccess = !currentAccess;
            await updateDoc(userRef, {
                hasPattiRowAccess: newAccess
            });
            // Update local state to reflect the change immediately
            setUsers(prevUsers => prevUsers.map(user => 
                user.id === userId ? {...user, hasPattiRowAccess: newAccess} : user
            ));
        } catch (error) {
            console.error('Error toggling patti row access:', error);
        }
    };

    const createUser = async () => {
        try {
            // Get the Firebase Auth instance
            const auth = getAuth();

            // Get the user's ID token (bearer token)
            const idToken = await auth.currentUser.getIdToken();

            // Get the Firebase Functions instance

            // Get the Cloud Function URL
            const createUserFunction = httpsCallable(functions, 'createUser', { region: 'asia-south1' });

            // Prepare headers
            const headers = {
                Authorization: `Bearer ${idToken}`,
            };

            // Prepare the payload
            const payload = { email: newUserEmail, password: newUserPassword };

            // Call the Cloud Function with proper headers
            const result = await createUserFunction(payload, { headers });

            setNewUserEmail('');
            setNewUserPassword('');
            await fetchUsers();
        } catch (error) {
            console.error('Error creating user:', error);
            // Handle error or display error message in the UI
        }
    };

    const toggleNoticeBoard = async (userId, hasAccess) => {
        try {
            const userRef = doc(db, 'users', userId);
            await updateDoc(userRef, {
                noticeBoardAccess: hasAccess
            });
            await fetchUsers();
        } catch (error) {
            console.error('Error updating notice board access:', error);
        }
    };

    return (
        <div className="flex flex-col flex-auto left-1/4">
            <h1 className="text-4xl font-bold mb-4">Super Admin</h1>
            <div className="mb-8">
                <table className="border-collapse border border-gray-400">
                    <thead>
                    <tr>
                        <th className="border border-gray-400 p-2">User</th>
                        <th className="border border-gray-400 p-2">Patti Row Access</th>
                        <th className="border border-gray-400 p-2">Assigned Draws</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td className="border border-gray-400 p-2">{user.email}</td>
                            <td className="border border-gray-400 p-2">
                                <label className="inline-flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={user.hasPattiRowAccess === true}
                                        onChange={() => togglePattiRowAccess(user.id, user.hasPattiRowAccess === true)}
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                    <span className="ml-2 text-white">Allow Access</span>
                                </label>
                            </td>
                            <td className="border border-gray-400 p-2">
                                {user['mumbai-pattern'] && (
                                    <div className="mt-2">
                                        <span className="font-bold">Mumbai Draws: </span>
                                        {user['mumbai-pattern'].map(drawId => {
                                            const draw = mumbaiDraws.find(item => item.id === drawId);
                                            if (draw) {
                                                return (
                                                    <div key={drawId} className="flex items-center">
                                                        <span>{draw.drawName}</span>
                                                        <button
                                                            className="ml-2 px-1 py-0.5 bg-red-500 text-white rounded hover:bg-red-600"
                                                            onClick={() => removeDraw(user.id, drawId, 'mumbai-pattern')}
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </div>
                                )}
                                {user['delhi-pattern'] && (
                                    <div className="mt-2">
                                        <span className="font-bold">Delhi Draws: </span>
                                        {user['delhi-pattern'].map(drawId => {
                                            const draw = delhiDraws.find(item => item.id === drawId);
                                            if (draw) {
                                                return (
                                                    <div key={drawId} className="flex items-center">
                                                        <span>{draw.drawName}</span>
                                                        <button
                                                            className="ml-2 px-1 py-0.5 bg-red-500 text-white rounded hover:bg-red-600"
                                                            onClick={() => removeDraw(user.id, drawId, 'delhi-pattern')}
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </div>
                                )}
                                <div className="mt-2 text-black">
                                    <button
                                        onClick={() => assignDraws(user.id, selectedMumbaiDraw, 'mumbai-pattern')}
                                        className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                                    >
                                        Assign Mumbai Draw
                                    </button>
                                    <select
                                        className="border border-gray-400 p-2 ml-2"
                                        value={selectedMumbaiDraw}
                                        onChange={e => setSelectedMumbaiDraw(e.target.value)}
                                    >
                                        <option value="">Select Mumbai Draw</option>
                                        {mumbaiDraws.map(draw => (
                                            <option key={draw.id} value={draw.id}>
                                                {draw.drawName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mt-2 text-black">
                                    <button
                                        onClick={() => assignDraws(user.id, selectedDelhiDraw, 'delhi-pattern')}
                                        className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                                    >
                                        Assign Delhi Draw
                                    </button>
                                    <select
                                        className="border border-gray-400 p-2 ml-2"
                                        value={selectedDelhiDraw}
                                        onChange={e => setSelectedDelhiDraw(e.target.value)}
                                    >
                                        <option value="">Select Delhi Draw</option>
                                        {delhiDraws.map(draw => (
                                            <option key={draw.id} value={draw.id}>
                                                {draw.drawName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="mb-8">
                <h2 className="text-xl text-black font-bold mb-2">Create User</h2>
                <div className="flex space-x-2">
                    <input
                        type="email"
                        placeholder="Email"
                        className="border text-black border-gray-400 p-1"
                        value={newUserEmail}
                        onChange={e => setNewUserEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        className="border text-black border-gray-400 p-1"
                        value={newUserPassword}
                        onChange={e => setNewUserPassword(e.target.value)}
                    />
                    <button
                        onClick={createUser}
                        className="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                    >
                        Create
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SuperAdminPage;