import React, { useEffect, useState } from 'react';
import { collection, deleteDoc, doc, getDoc, setDoc, updateDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useSite } from "../../siteContext";

const HighlightPatti = () => {
    const [mumbaiDraws, setMumbaiDraws] = useState([]);
    const [selectedDraw, setSelectedDraw] = useState('');
    const [highlightedDraws, setHighlightedDraws] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { site } = useSite();

    const fetchMumbaiDraws = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'mumbai-pattern-draws'));
            const drawsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setMumbaiDraws(drawsData);
        } catch (error) {
            console.error('Error fetching Mumbai draws:', error);
        }
    };

    const fetchHighlightedDraws = async () => {
        try {
            const configDocRef = doc(db, 'config', site === "android" ? "config" : site);
            const configDocSnapshot = await getDoc(configDocRef);

            if (configDocSnapshot.exists()) {
                const configData = configDocSnapshot.data();
                const highlightedDrawsArray = configData['highlightedDraws'] || [];
                setHighlightedDraws(highlightedDrawsArray);
            }
        } catch (error) {
            console.error('Error fetching highlighted draws:', error);
        }
    };

    const addToHighlightedDraws = async () => {
        if (!selectedDraw) return;

        setIsLoading(true);
        try {
            const configDocRef = doc(db, 'config', site === "android" ? "config" : site);
            await updateDoc(configDocRef, {
                'highlightedDraws': [...highlightedDraws, selectedDraw]
            });
            
            setSelectedDraw('');
            await fetchHighlightedDraws();
        } catch (error) {
            console.error('Error adding draw to highlighted list:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const removeFromHighlightedDraws = async (drawId) => {
        setIsLoading(true);
        try {
            const configDocRef = doc(db, 'config', site === "android" ? "config" : site);
            const updatedDraws = highlightedDraws.filter(id => id !== drawId);
            
            await updateDoc(configDocRef, {
                'highlightedDraws': updatedDraws
            });

            await fetchHighlightedDraws();
        } catch (error) {
            console.error('Error removing draw from highlighted list:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchMumbaiDraws();
        fetchHighlightedDraws();
    }, [site]);

    return (
        <div className="flex flex-col items-center justify-center mx-auto mt-16 p-8">
            <h1 className="text-4xl font-bold mb-8 text-white">Highlight Patti Management</h1>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 w-full max-w-7xl">
                {/* Add Draw Section */}
                <div className="flex flex-col w-full bg-white shadow-md rounded-lg p-6">
                    <h3 className="text-xl font-semibold mb-6 text-black">Add New Highlight</h3>
                    <div className="space-y-6">
                        <div>
                            <label 
                                htmlFor="drawSelect" 
                                className="text-xl font-semibold mb-2 block text-black"
                            >
                                Select Draw
                            </label>
                            <select
                                id="drawSelect"
                                className="text-xl p-3 border border-gray-800 rounded-md w-full text-black"
                                value={selectedDraw}
                                onChange={(e) => setSelectedDraw(e.target.value)}
                            >
                                <option value="">Select Draw</option>
                                {mumbaiDraws.map((draw) => (
                                    <option key={draw.id} value={draw.id}>
                                        {draw.drawName} ({draw.startTime} - {draw.closingTime})
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button
                            onClick={addToHighlightedDraws}
                            disabled={isLoading || !selectedDraw}
                            className={`mt-4 px-6 py-3 text-lg font-semibold text-white rounded-md transition duration-300 ease-in-out
                                ${isLoading || !selectedDraw 
                                    ? 'bg-gray-400 cursor-not-allowed'
                                    : 'bg-blue-500 hover:bg-blue-600'
                                }`}
                        >
                            {isLoading ? (
                                <span className="flex items-center justify-center">
                                    <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none"/>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                                    </svg>
                                    Adding...
                                </span>
                            ) : 'Add to Highlighted Draws'}
                        </button>
                    </div>
                </div>

                {/* Highlighted Draws List */}
                <div className="flex flex-col w-full bg-white shadow-md rounded-lg p-6">
                    <h3 className="text-xl font-semibold mb-6 text-black">Currently Highlighted Draws</h3>
                    <div className="space-y-4">
                        {highlightedDraws.length === 0 ? (
                            <div className="text-center py-8">
                                <p className="text-gray-600">No draws are currently highlighted</p>
                            </div>
                        ) : (
                            highlightedDraws.map((drawId) => {
                                const draw = mumbaiDraws.find(d => d.id === drawId);
                                return draw ? (
                                    <div 
                                        key={drawId} 
                                        className="flex items-center justify-between p-4 bg-gray-50 rounded-md border border-gray-300"
                                    >
                                        <div className="flex-1">
                                            <h4 className="text-lg font-medium text-black">{draw.drawName}</h4>
                                            <p className="text-gray-600">
                                                {draw.startTime} - {draw.closingTime}
                                            </p>
                                        </div>
                                        <button
                                            onClick={() => removeFromHighlightedDraws(drawId)}
                                            disabled={isLoading}
                                            className={`ml-4 px-6 py-3 text-lg font-semibold text-white rounded-md transition duration-300 ease-in-out
                                                ${isLoading 
                                                    ? 'bg-gray-400 cursor-not-allowed'
                                                    : 'bg-blue-500 hover:bg-blue-600'
                                                }`}
                                        >
                                            {isLoading ? 'Removing...' : 'Remove'}
                                        </button>
                                    </div>
                                ) : null;
                            })
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HighlightPatti;